import LBROracle from 'abis/LBROracle.json'
import LybraMintPool from 'abis/LybraMintPool.json'
import MatchAbi from 'abis/MatchPool.json'
import MatchPublicSaleAbi from 'abis/MatchPublicSale.json'
import MatchTokenAbi from 'abis/MatchToken.json'
import MatchVestingAbi from 'abis/MatchVesting.json'
import MatchWhitelistSaleAbi from 'abis/MatchWhitelistSale.json'
import MiningIncentiveAbi from 'abis/MiningIncentive.json'
import MockUSDAbi from 'abis/MockUSD.json'
import RewardManagerAbi from 'abis/RewardManager.json'
import LybraStakePool from 'abis/StakePool.json'
import VLMatchTokenAbi from 'abis/VLMatch.json'
import VLMatchStakingAbi from 'abis/VLMatchStaking.json'
import VLMatchVestingAbi from 'abis/VLMatchVesting.json'
import { mainnetRpc } from 'config/rpcConfig'
import Web3 from 'web3'

export const DEFAULT_CHAIN_ID = '0x1'

export const getDecimals = (Decimals) => {
  switch (Decimals) {
    case 0:
      return 'noether'
    case 1:
      return 'wei'
    case 3:
      return 'kwei'
    case 6:
      return 'mwei'
    case 9:
      return 'gwei'
    case 12:
      return 'microether'
    case 15:
      return 'milliether'
    case 18:
      return 'ether'
    case 21:
      return 'kether'
    case 24:
      return 'mether'
    case 27:
      return 'gether'
    case 30:
      return 'tether'
    default:
      return Decimals
  }
}

export const fromWei = (FixNumber, Decimals) => {
  const FixDecimals = getDecimals(Decimals || 18)
  if (typeof FixNumber === 'number') {
    const result = FixNumber / 10 ** (Decimals || 18)
    if (result.toFixed(8) - 1e-8 < 0) {
      return 0
    }
    // return parseFloat(result.toFixed(8))
    return result
  } else {
    const result = Number(Web3.utils.fromWei(FixNumber, FixDecimals))
    if (result.toFixed(8) - 1e-8 < 0) {
      return 0
    }
    // return parseFloat(result.toFixed(8))
    return result
  }
}

export const toWei = (FixNumber, Decimals = 18) => {
  let result
  try {
    const FixDecimals = getDecimals(Decimals)
    if (typeof FixNumber === 'number') {
      result = Web3.utils.toWei(FixNumber.toFixed(Decimals), FixDecimals)
    } else {
      if (isDecimal(FixNumber)) FixNumber = trimDecimals(FixNumber, Decimals)
      result = Web3.utils.toWei(FixNumber, FixDecimals)
    }
    // console.log('to Wei result', result);
    return result
  } catch (err) {
    console.warn(err)
  }
}

// FixNumber is string, 0 decimals -> convert to integer, -1 decimals -> trim last decimal
export const trimDecimals = (num, decimals = -1) => {
  num = String(num)
  if (!num.includes('.')) return num

  const temp = num.split('.')

  if (decimals == 0) return temp[0]

  if (temp.length == 1) return num
  else temp[1] = decimals == -1 ? temp[1].slice(0, temp[1].length - 1) : temp[1].slice(0, decimals)

  return temp[0] + '.' + temp[1]
}

export const injectedProvider = () =>
  navigator && /OKApp/i.test(navigator.userAgent) ? okxwallet : ethereum
export const WEB3 = () => {
  // console.log('new web3')
  try {
    return new Web3(injectedProvider())
  } catch (e) {
    console.log(e)
    return new Web3(new Web3.providers.HttpProvider('https://ethereum.publicnode.com'))
  }
}

export const readOnlyWeb3 = () =>
  new Web3(new Web3.providers.HttpProvider('https://ethereum.publicnode.com'))
// new Web3(window.ethereum || new Web3.providers.HttpProvider('https://ethereum.publicnode.com'))
// new Web3(new Web3.providers.HttpProvider('https://ethereum.publicnode.com'))

export const getGasPrice = () => readOnlyWeb3().eth.getGasPrice()

export const getChainId = async () => {
  const chainId = await injectedProvider().request({ method: 'eth_chainId' })
  return chainId
}

// export const getChainInfo = (field, chainId = DEFAULT_CHAIN_ID) => {
//   /**
//    * param chainId: which chainId for
//    * param field: which field to take, can be: chainName, rpc, blockExplorer, wethAddress
//    */
//   const param = getNetworkParam(chainId)
//   return param[field]
// }

export const signData = async (message, address) => {
  const web3 = WEB3()
  const signature = await web3.eth.personal.sign(message, address)
  return signature
}

export const getContract = async (abi, address, readOnly = true) => {
  // console.log('get contract', address)
  const web3 = readOnly ? readOnlyWeb3() : WEB3()
  // console.log('web3 instance', web3)
  if (address === '') {
    try {
      return new web3.eth.Contract(abi.abi, abi.address)
    } catch (e) {
      return new web3.eth.Contract(abi, abi.address)
    }
  }
  try {
    return new web3.eth.Contract(abi.abi, address)
  } catch (e) {
    return new web3.eth.Contract(abi, address)
  }
}

export const getNativeTokenAmount = async (account) => {
  const web3 = readOnlyWeb3()

  // console.log('getting native bal');
  // console.log('current provider', web3);

  const balance = await web3.eth.getBalance(account)
  // console.log("native balnce", Number(fromWei(balance)));
  return Number(fromWei(balance))
}

const mintPools = {
  stETH: '0xa980d4c0C2E48d305b582AA439a3575e3de06f0E',
  wstETH: '0x5e28B5858DA2C6fb4E449D69EEb5B82e271c45Ce',
  wbETH: '0xB72dA4A9866B0993b9a7d842E5060716F74BF262',
  rETH: '0x090B2787D6798000710a8e821EC6111d254bb958',
}

const tokenAddress = {
  dLP: '0x3A0eF60e803aae8e94f741E7F61c7CBe9501e569',
  stETH: '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
  eUSD: '0xdf3ac4F479375802A821f7b7b46Cd7EB5E4262cC',
  eUSDOld: '0x97de57eC338AB5d51557DA3434828C5DbFaDA371',
}
export const getTokenContract = async (token, readOnly = true) => {
  return getContract(MockUSDAbi, tokenAddress[token], readOnly)
}

export const METCH_CONTRACT_ADDRESS = '0x04b9ce11da7323aEf03f6e6c16C0b93cFB44C55c'
export const stETH_MINT_POOL = mintPools['stETH']
export const getMatchContract = async (readOnly = true) => {
  // console.log('get match contract')
  return getContract(MatchAbi, METCH_CONTRACT_ADDRESS, readOnly)
}

export const getRewardContract = async (readOnly = true) => {
  return getContract(RewardManagerAbi, '0x6a2f2C99d5b99fb8BA280Ca6Aa56C7a82B6af503', readOnly)
}
export const getLybraMintPoolContract = async (poolName = 'stETH') => {
  return getContract(LybraMintPool, mintPools[poolName])
}

export const getLybraStakePoolContract = async () => {
  return getContract(LybraStakePool, '0xEc7c6cD15d9Bd98Fc9805E0509E3bb2033C5956D')
}

export const getMiningIncentiveContract = async () => {
  return getContract(MiningIncentiveAbi, '0x0B2659734121FeB636534213a159AC91691eDbde')
}

export const getLBROracle = async () => {
  return getContract(LBROracle, '0x1932d36f5Dd86327CEacd470271709a931803338')
}

export const cutOffNum = (num, digits) =>
  parseFloat((Math.floor(num * 10 ** digits) / 10 ** digits).toFixed(digits))

export const MATCH_VESTING_ADDR = '0x392f85DeBBEad1b532E3E9B7008caFB643725374'
export const getMatchVestingContract = () => getContract(MatchVestingAbi, MATCH_VESTING_ADDR, false)
  
const MATCH_WHITELIST_SALE_ADDR = '0x897888EBC122E476415E62bc6a793A7A4Af87bFc'
export const getMatchWhitelistSaleContract = (readOnly) =>
  getContract(MatchWhitelistSaleAbi, MATCH_WHITELIST_SALE_ADDR, readOnly)
const MATCH_PUBLIC_SALE_ADDR = '0x7A2B1E2f87CEAB347eD46DEFbF15F53FaeE0708e'
export const getMatchPublicSaleContract = (readOnly) =>
  getContract(MatchPublicSaleAbi, MATCH_PUBLIC_SALE_ADDR, readOnly)
export const MATCH_TOKEN_ADDR = '0xe0DcB3E02798D1c6a9650fe1381015ec34705153'
export const getMatchTokenContract = (readOnly) =>
  getContract(MatchTokenAbi, MATCH_TOKEN_ADDR, readOnly)

const VLMATCH_ADDR = '0x5Ef24e853891D91efE6e19B3F438FaE66b14A99A'
export const getVLMathContract = (readOnly) => getContract(VLMatchTokenAbi, VLMATCH_ADDR, readOnly);
export const VLMATCH_VESTING_ADDR = '0x5D76c19a6E39b7ED45237010CF608D1D1cB68abe'
export const getVLMathVestingContract = (readOnly) => getContract(VLMatchVestingAbi, VLMATCH_VESTING_ADDR, readOnly);
const VLMATCH_STAKING_ADDR = '0x7D027083e55724A1082b8cDC51eE90781f41Ff14'
export const getVLMathStakingContract = (readOnly) => getContract(VLMatchStakingAbi, VLMATCH_STAKING_ADDR, readOnly);